import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["templateNav", "containerNav", "templateTab", "containerTab", "addButton"]

  addSection(event) {
    event.preventDefault()
    const newId = new Date().getTime()
    this.insertNavSection(newId)
    this.insertTabSection(newId)
  }

  removeSection(event) {
    event.stopPropagation()
    if (!window.confirm("Are you sure?")) return

    const sectionId = event.target.dataset.sectionId
    const recordId = event.target.getAttribute('data-target-id');
    this.removeElement(`[data-target-id='${sectionId}']`, this.containerNavTarget)
    this.removeElement(`#${sectionId}`, this.containerTabTarget)
    this.markForDestruction(recordId)
  }

  insertNavSection(newId) {
    const html = this.templateNavTarget.innerHTML.replace(/NEW_RECORD/g, newId)
    this.addButtonTarget.insertAdjacentHTML('beforebegin', html)
  }

  insertTabSection(newId) {
    const html = this.templateTabTarget.innerHTML.replace(/NEW_RECORD/g, newId)
    this.containerTabTarget.insertAdjacentHTML("beforeend", html)
  }

  removeElement(selector, container) {
    const element = container.querySelector(selector)
    if (element) element.remove()
  }

  markForDestruction(recordId) {
    const destroyInput = document.querySelector(`input[data-target-id="${recordId}"]`)
    if (destroyInput) destroyInput.value = "1"
  }
}