import { Controller } from "@hotwired/stimulus"
import countdown from 'countdown';

export default class extends Controller {
  static targets = [ 'dates', 'days', 'hours', 'minutes', 'seconds' ]

  connect() {
    this.engageCountdown();
  }

  engageCountdown(e) {
    let targetDate = new Date(this.datesTarget.getAttribute('data-start-date'));
    let currentDate = new Date();
    let remainingTime = countdown(currentDate, targetDate, countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS);
    let daysElement = this.daysTarget;
    let hoursElement = this.hoursTarget;
    let minutesElement = this.minutesTarget;
    let secondsElement = this.secondsTarget;
    setInterval(function() {
      let currentDate = new Date();
      let remainingTime = countdown(currentDate, targetDate, countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS);
      daysElement.innerHTML = remainingTime.days;
      hoursElement.innerHTML = remainingTime.hours;
      minutesElement.innerHTML = remainingTime.minutes;
      secondsElement.innerHTML = remainingTime.seconds;
    }, 1000);
  }
}
