// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
import "./controllers";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Choices from "choices.js";
import flatpickr from "flatpickr";
import './loading_images.js';

$(document).on('turbo:load', function() {
  if ($('select#article_tag_ids').length > 0) {
    new Choices($('select#article_tag_ids')[0], {
      allowHTML: true,
      removeItems: true,
      removeItemButton: true
    });
  }

  if ($('select#email_user_emails').length > 0) {
    new Choices($('select#email_user_emails')[0], {
      allowHTML: true,
      removeItems: true,
      removeItemButton: true
    });
  }

  if ($('[data-behaviour="datetimepicker"]').length > 0) {
    flatpickr($('[data-behaviour="datetimepicker"]'), {
      enableTime: true,
      time_24hr: true,
      dateFormat: "d-m-Y H:i"
    });
  }

  $('.navbar-toggler').click(function () {
    toggleNavbarCollapseArea();
  });

  $(window).click(function(event) {
    if(!window.location.pathname.includes('admin')) {
      if (document.querySelector("#navbarSupportedContent").classList.contains('show')) {
        if (!event.target.matches('.navbar-collapse') && !event.target.matches('.navbar-collapse *')) {
          toggleNavbarCollapseArea();
        }
      }
    }
  });

  $(".event-button").on('click', function(event) {
    event.currentTarget.blur();
  });

  if ($('#carouselBannerImages').length > 0) {
    const myCarouselElement = document.querySelector('#carouselBannerImages')
    new bootstrap.Carousel(myCarouselElement, {
      interval: 4500,
    });
  };

  if ($('#loginModal').length > 0) {
    new bootstrap.Modal(document.getElementById('loginModal'), {});
  };

  let fileInputs = $("input[data-validation='maxFileSize']");
  if (fileInputs.length > 0) {
    let maxFileSize = 1 * 1024 * 1024; // 1MB
    let fileSizeValidationMsg = fileInputs[0].dataset.validationMessage;

    fileInputs.each(function () {
      this.addEventListener('change', (event) => {
        let target = event.target
        if (target.files && target.files[0]) {
          if (target.files[0].size > maxFileSize) {
            event.preventDefault();
            target.value = ''
            alert(fileSizeValidationMsg);
          }
        }
      })
    });

    document.addEventListener('trix-file-accept', function(event) {
      let fileSize = event.file.size;

      if (fileSize > maxFileSize) {
        event.preventDefault();
        alert(fileSizeValidationMsg);
      }
    });
  };

  const videos = document.querySelectorAll('video');

  videos.forEach(function(video) {
    video.addEventListener('loadedmetadata', function() {
      capturePoster(video);
    });

    video.addEventListener('error', function() {
      console.error('Video load error:', video.error);
    });
  });

  setTimeout(function() {
    $('.flash-message').alert('close');
  }, 10000);
});

function toggleNavbarCollapseArea() {
  const myCollapseElement = document.querySelector("#navbarSupportedContent");
  new bootstrap.Collapse(myCollapseElement).toggle();
};

function capturePoster(video) {
  var canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  var poster = canvas.toDataURL();
  video.setAttribute('poster', poster);
}

$(window).scroll(function () {
  var window_top = $(window).scrollTop() + 1;
  if (window_top > 10) {
    $('.main-menu').addClass('scrolled');
    $('div.navbar-collapse').addClass('scrolled');
  } else {
    $('.main-menu').removeClass('scrolled');
    $('div.navbar-collapse').removeClass('scrolled');
  }
});
