document.addEventListener('turbo:load', function() {
  toggleLoadingImages();
});

function toggleLoadingImages() {
  var loadingClass = 'loading';
  var loadedClass = 'loaded';
  var images = $(`.${loadingClass}`);
  images.each(function() {
    var image = $(this);

    image.on('load', function() {
      image.addClass(loadedClass);
      image.removeClass(loadingClass)
    });

    // Check if the image is already loaded
    if (image[0].complete && image[0].naturalHeight !== 0) {
      image.addClass(loadedClass);
      image.removeClass(loadingClass)
    }
  });
}