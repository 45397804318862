import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [ 'cookiesModal', 'tooltipElement', 'analytic', 'preference', 'marketing' ]

  connect() {
    this.pollForConsentTargetsExistence();
  }

  pollForConsentTargetsExistence() {
    const pollInterval = setInterval(() => {
      if (this.hasAnalyticTarget && this.hasMarketingTarget && this.hasPreferenceTarget) {
        clearInterval(pollInterval);
        this.setDefaultCookies();
      }
    }, 100);
  }

  showCookies(event) {
    event.preventDefault();

    if (this.cookiesModalTarget.classList.contains('show'))
      this.cookiesModalTarget.classList.remove("show");
    else
      this.cookiesModalTarget.classList.add("show");
  }

  showSetting(event) {
    event.preventDefault();

    if (this.cookiesModalTarget.classList.contains('with-setting'))
      this.cookiesModalTarget.classList.remove("with-setting");
    else
      this.cookiesModalTarget.classList.add("with-setting");

    this.tooltipElementTargets.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  gtagAction(action) {
    return (...args) => {
      if (typeof gtag !== 'undefined') {
        action.apply(this, args);
      } else {
        console.warn('gtag is not defined. consent actions will not be tracked.');
      }
    };
  }

  setDefaultCookies = this.gtagAction(() => {
    const consents = this.getConsents();
    gtag('consent', 'default', consents);
  });

  acceptAllCookies = this.gtagAction(() => {
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
  });

  acceptSelectedCookies = this.gtagAction(() => {
    const consents = this.getConsents();
    gtag('consent', 'update', consents);
  });

  rejectCookies = this.gtagAction(() => {
    gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });
  });

  getConsents() {
    return {
      'ad_storage': this.marketingTarget.checked ? 'granted' : 'denied',
      'ad_user_data': this.marketingTarget.checked ? 'granted' : 'denied',
      'ad_personalization': this.marketingTarget.checked ? 'granted' : 'denied',
      'analytics_storage': this.analyticTarget.checked ? 'granted' : 'denied'
    };
  }
}
