import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  redirectToArticle(event) {
    if (['a', 'button'].includes(event.target.tagName.toLowerCase())) {
      return;
    };
    let newPath = event.currentTarget.getAttribute('data-url-target');
    document.location.href = newPath;
  }
}